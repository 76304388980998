<template>
  <div class="factory-block">
    <div class="factory-top flex">
      <div class="ft1 flex">
        <div @click="viewZizhi(item.id)" class="factory-logo flex flex-center">
          <i class="iconfont icon-shengchanqiye1x"></i>
        </div>
        <div class="ft1-right flex-1">
          <div class="fr-top flex">
            <div @click="viewZizhi(item.id)" class="name ellipsis"><b>{{item.name}}</b></div>
            <div class="tag">
              <div v-if="item.cert_stat==1">已认证</div>
              <div v-if="item.cert_stat==2" style="text-align:center;">
                未认证<span class="dot"></span>
              </div>
            </div>
          </div>
          <div class="btn flex flex-align-center">
            <el-button @click="get_phone()" size="mini" type="primary">联系方式</el-button>
            <div class="icon-view">
              <!-- <i class="iconfont icon-liulanbeifen4"></i><span>222</span> -->
            </div>
            <el-button @click="goto('/want/wantIssue')" style="margin:0 20px;" size="mini" type="primary" plain>找TA定制</el-button>
            <el-button @click="viewZizhi(item.id)" style="margin-right:20px;" size="mini" type="primary">资质详情</el-button>
            <!-- <el-button @click="applyWhite(item)" size="mini" type="primary">申请白名单</el-button> -->
          </div>
          <div class="tit-wrap">
            <div v-if="item.aptitude && item.aptitude.ability_service">
              <!-- <color-label class="tag"
                v-for="(n, i) in item.aptitude.ability_service.fwbq"
                :key="i"
                :text="n.v"/> -->
                <span class="tag"
                  v-for="(n, i) in item.aptitude.ability_service.fwbq"
                  :key="i">{{n.v}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ft2 flex-1 flex flex-center">
        <div class="sale_percent">
          <div class="percent_progress">
            <el-progress
              type="circle"
              :percentage="item.aptitude_score"
              :width="100"
              :stroke-width="8"
              color="#FD8895"
            ></el-progress>
          </div>
          <div>资质完善度</div>
        </div>
      </div>
      <div @click="goMarket(item.id)" class="ft3 flex-1 flex flex-center">
        <div>
          <div class="text">在售商品</div>
          <div class="num">{{item.onsale}}</div>
        </div>
      </div>
      <!-- <div class="flex flex-align-center">
        <div class="img-right">
          <div class="tit-wrap">
            <div class="name">
              <span>{{item.name}}</span>
            </div>
            <div v-if="item.aptitude && item.aptitude.ability_service">
              <color-label
                v-for="(n, i) in item.aptitude.ability_service.fwbq"
                :key="i"
                :text="n.v"
              />
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-show="item.aptitude" class="factory-btm flex">
      <div class="top flex flex-justify-between">
        <div class="label-container flex">
          <div class="icon-tag flex flex-center">
            <i class="iconfont icon-jiagongjilu"></i>
          </div>
          <div class="lc-right flex-1">
            <label><b>生产属性</b></label>
            <div v-if="jgsx && jgsx.length>0">
              <!-- <color-label class="tag" v-for="(n, i) in jgsx" :key="i" type="2" :text="n.v" /> -->
              <span class="tag" v-for="(n, i) in jgsx" :key="i">{{n.v}}</span>
            </div>
            <div v-else class="default">暂无数据</div>
          </div>
        </div>
        <div class="label-container flex">
          <div class="icon-tag flex flex-center" style="background:#1C3DD7;">
            <i class="iconfont icon-mucai"></i>
          </div>
          <div class="lc-right flex-1">
            <label><b>主要木材</b></label>
            <div v-if="mczl && mczl.length>0">
              <!-- <color-label v-for="(n, i) in mczl" :key="i" type="2" :text="n.v" /> -->
              <span class="tag" v-for="(n, i) in mczl" :key="i">{{n.v}}</span>
            </div>
            <div v-else class="default">暂无数据</div>
          </div>
        </div>
        <div class="label-container flex">
          <div class="icon-tag flex flex-center" style="background:#F66F6A;">
            <i class="iconfont icon-waixiegongzuo"></i>
          </div>
          <div class="lc-right flex-1">
            <label><b>外协加工</b></label>
            <div v-if="item.aptitude && item.aptitude.ability_service && item.aptitude.ability_service.wx && item.aptitude.ability_service.wx.length>0">
              <!-- <color-label
                v-for="(n, i) in item.aptitude.ability_service.wx"
                :key="i" type="2" :text="n.v"/> -->
                <span class="tag"
                    v-for="(n, i) in item.aptitude.ability_service.wx"
                    :key="i">{{n.v}}</span>
            </div>
            <div v-else class="default">暂无数据</div>
          </div>
        </div>
        <div class="label-container flex">
          <div class="icon-tag flex flex-center" style="background:#2BBAAE;">
            <i class="iconfont icon-jidaohang_zhudamaidian"></i>
          </div>
          <div class="lc-right flex-1">
            <label><b>主打产品</b></label>
            <div v-if="item.aptitude && item.aptitude.ability_service && item.aptitude.ability_service.zdcp && item.aptitude.ability_service.zdcp.length>0">
              <!-- <color-label v-for="(n, i) in item.aptitude.ability_service.zdcp"
                :key="i" type="2" :text="n.v"/> -->
                <span class="tag"
                    v-for="(n, i) in item.aptitude.ability_service.zdcp"
                    :key="i">{{n.v}}</span>
            </div>
            <div v-else class="default">暂无数据</div>
          </div>
        </div>
        <!-- <div class="flex flex-align-center">
          
        </div>
        <div class="flex flex-align-center" style="margin-top:8px;">
          
        </div> -->
      </div>
      <div class="bottom">
        <div class="b-top flex">
          <div class="bottom-item">
            <div class="tit">成立日期</div>
            <div class="text">
              <div v-if="item.aptitude&&item.aptitude.stat && item.aptitude.stat.reg_date">{{item.aptitude.stat.reg_date | moment("YYYY-MM-DD")}}</div>
              <div v-else>无</div>
            </div>
          </div>
          <div class="bottom-item">
            <div class="tit">工厂面积</div>
            <div class="text">
              <div v-if="item.aptitude&&item.aptitude.stat&&item.aptitude.stat.area">{{item.aptitude.stat.area}}m²</div>
              <div v-else>无</div>
            </div>
          </div>
          <div class="bottom-item">
            <div class="tit">总产值</div>
            <div class="text">
              <div v-if="item.aptitude&&item.aptitude.stat && item.aptitude.stat.amount">{{item.aptitude.stat.amount}}万</div>
              <div v-else>无</div>
            </div>
          </div>
          <div class="flex-1">
            <div class="tit">工艺能力</div>
            <div class="text">
              <div v-if="gynl">{{gynl}}</div>
              <div v-else>无</div>
            </div>
          </div>
        </div>
        <div class="b-bottom flex">
          <div class="bottom-item">
            <div class="tit">总人数</div>
            <div class="text">
              <div v-if="item.aptitude&&item.aptitude.stat&& employeeArr[item.aptitude.stat.n_employee - 1] && employeeArr[item.aptitude.stat.n_employee - 1].name">
                {{employeeArr[item.aptitude.stat.n_employee - 1] && employeeArr[item.aptitude.stat.n_employee - 1].name}}
              </div>
              <div v-else>无</div>
            </div>
          </div>
          <div class="bottom-item">
            <div class="tit">生产设备</div>
            <div class="text">
              <div v-if="item.aptitude&&item.aptitude.stat&&item.aptitude.stat.n_equipment">{{item.aptitude.stat.n_equipment}}台</div>
              <div v-else>无</div>
            </div>
          </div>
          <div class="flex-1">
            <div class="tit">区域</div>
            <div class="text">
              <div v-if="item.aptitude&&item.aptitude.stat&&item.aptitude.stat.zone_str">{{item.aptitude.stat.zone_str}}</div>
              <div v-else>无</div>
            </div>
          </div>
        </div>
      </div>
      <!-- @click="goto('/sale/mall/home/factory?search='+item.name)" -->
      <!-- <div class="mid flex">
        <div class="table-wrap">
          <div class="table">
            <div class="flex">
              <div>成立日期</div>
              <div>
                <div v-if="item.aptitude&&item.aptitude.stat && item.aptitude.stat.reg_date">{{item.aptitude.stat.reg_date | moment("YYYY-MM-DD")}}</div>
              </div>
              <div>工厂面积</div>
              <div>
                <div v-if="item.aptitude&&item.aptitude.stat">{{item.aptitude.stat.area}}m²</div>
              </div>
            </div>
            <div class="flex">
              <div>总产值</div>
              <div>
                <div v-if="item.aptitude&&item.aptitude.stat">{{item.aptitude.stat.amount}}万</div>
              </div>
              <div>总人数</div>
              <div>
                <div v-if="item.aptitude&&item.aptitude.stat">{{employeeArr[item.aptitude.stat.n_employee - 1] && employeeArr[item.aptitude.stat.n_employee - 1].name}}</div>
              </div>
            </div>
            <div class="flex">
              <div>工艺能力</div>
              <div>
                <div v-if="item.aptitude&&item.aptitude.stat">{{gynl}}</div>
              </div>
              <div>生产设备</div>
              <div>
                <div v-if="item.aptitude&&item.aptitude.stat">{{item.aptitude.stat.n_equipment}}台</div>
              </div>
            </div>
            <div class="flex">
              <div>区域</div>
              <div>
                <div v-if="item.aptitude&&item.aptitude.stat">{{item.aptitude.stat.zone_str}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mid-block flex-1 flex flex-center" style="cursor: pointer;">
          <div class="sale_num">
            <div>{{item.onsale || 0}}</div>
            <div>在售商品</div>
          </div>
        </div>
        <div class="mid-block flex-1 flex flex-center">
          <div class="sale_prove">
            <div v-if="item.cert_stat==1">企业已认证</div>
            <div v-if="item.cert_stat==2">企业未认证</div>
          </div>
        </div>
        <div class="mid-block flex-1 flex flex-center">
          <div class="sale_percent">
            <div class="percent_progress">
              <el-progress
                type="circle"
                :percentage="item.aptitude_score"
                :width="60"
                :stroke-width="5"
                color="#FD8895"
              ></el-progress>
            </div>
            <div>资质完善度</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="btn-wrap flex">
        <div class="flex-1">
          <div @click="get_phone(item)" class="icon-btn">
            <i class="iconfont icon-dianhuabeifen"></i>
            <span>联系方式</span>
          </div>
        </div>
        <div>
          <el-button @click="goto('/want/wantIssue')" size="small" type="primary" plain>找TA定制</el-button>
          <el-button @click="viewZizhi(item.id)" size="small" type="primary">资质详情</el-button>
        </div>
      </div> -->
    </div>

    <el-dialog :title="popTitle" :visible.sync="popVisible" width="500px" :destroy-on-close="true">
        <ItemPhone v-if="popTitle=='联系方式'" :type="5" :compId="item.id" @hide="popVisible=false"></ItemPhone>
        <ApplyWhite v-if="popTitle=='申请白名单'" :factoryId="item.id" @hide="popVisible=false"></ApplyWhite>
    </el-dialog>

  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
// import ColorLabel from "@/components/factory/ColorLabel.vue";
import ItemPhone from '@/components/comp/ItemPhone.vue';
import ApplyWhite from '@/components/comp/ApplyWhite.vue';
export default {
  components: { ItemPhone, ApplyWhite },
  props: {
    item: {
      type: Object
    },
  },
  data() {
    return {
      IMG_URL_PRE,
      popTitle: null,
      popVisible: false,
      colorArr: ["#97AFE2", "#E1A5D7", "#E6F182", "#A5DFCC"],
      employeeArr: [
        {
          name: "1-20人",
          value: 1
        },
        {
          name: "21-50人",
          value: 2
        },
        {
          name: "51-100人",
          value: 3
        },
        {
          name: "101-200人",
          value: 4
        },
        {
          name: "201-500人",
          value: 5
        },
        {
          name: "501-1000人",
          value: 6
        },
        {
          name: "1001人以上",
          value: 7
        }
      ],
    };
  },
  computed: {
    jgsx() {
      if (this.item.aptitude && this.item.aptitude.ability_service && this.item.aptitude.ability_service.jgsx && this.item.aptitude.ability_service.jgsx.length>0) {
        // return this.item.aptitude.ability_service.jgsx.slice(3);
        return this.item.aptitude.ability_service.jgsx;
      } else 
      return null;
    },
    mczl() {
      // if (this.item.aptitude.ability_service.mczl.length > 3) {
      //   return this.item.aptitude.ability_service.mczl.slice(3);
      // }
      if (this.item.aptitude && this.item.aptitude.ability_service && this.item.aptitude.ability_service.mczl && this.item.aptitude.ability_service.mczl.length>0) {
        return this.item.aptitude.ability_service.mczl;
      } else 
      return null;
    },
    wx() {
      // if (this.item.aptitude.ability_service.wx.length > 3) {
      //   return this.item.aptitude.ability_service.wx.slice(3);
      // }
      if (this.item.aptitude && this.item.aptitude.ability_service && this.item.aptitude.ability_service.wx && this.item.aptitude.ability_service.wx.length>0) {
        return this.item.aptitude.ability_service.wx;
      } else 
      return null;
    },
    zdcp() {
      // if (this.item.aptitude.ability_service.zdcp.length > 3) {
      //   return this.item.aptitude.ability_service.zdcp.slice(3);
      // }
      if (this.item.aptitude && this.item.aptitude.ability_service && this.item.aptitude.ability_service.zdcp && this.item.aptitude.ability_service.zdcp.length>0) {
        return this.item.aptitude.ability_service.zdcp;
      } else 
      return null;
    },
    gynl() {
      if (this.item.aptitude && this.item.aptitude.ability_make && this.item.aptitude.ability_make.length>0) {
        return this.item.aptitude.ability_make
        ? this.item.aptitude.ability_make.map(n => n.name).join("、")
        : "无";
      } else 
      return null;
    },
    // zone() {
    //   if (this.zoneList && this.zoneList.length>0) {
    //     return this.zoneList[this.item.aptitude.stat.zone].name;
    //   } else 
    //   return "无";
    // }
  },
  created() {},
  mounted() {},
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    // goSearch() {
    //   this.$router.push('/sale/mall/home/factory?search='+item.name);
    // },
    get_phone() {
        this.popTitle = "联系方式";
        this.popVisible = true;
    },
    goMarket(id) {
      this.$router.push("/sale/mall/home/factory?compId="+id);
    },
    viewZizhi(id) {
        window.open('/zizhi/view/'+id);
    },
    applyWhite(item) {
      this.popTitle = "申请白名单";
      this.popVisible = true;
    }
  }
};
</script>
<style src="../style.css"></style>
<style scoped>
.hide {
  visibility: hidden;
}
.ant-carousel >>> .slick-slide {
  height: 228px;
}

.ant-carousel >>> .custom-slick-arrow i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 16px;
  color: #ccc;
  background: #fff;
  border-radius: 50%;
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
</style>
<style lang="less" scoped>
.factory-block {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  // border-radius: 4px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .factory-top {
    padding-bottom: 14px;
    .ft1 {
      width: 824px;
      padding-right: 20px;
      border-right: 1px solid #E6E6E6;
      .factory-logo {
        width: 48px;
        height: 48px;
        background: #EDF1FF;
        border-radius: 50%;
        margin-right: 12px;
        cursor: pointer;
        i {
          font-size: 28px;
          color: #5074EE;
        }
      }
      .ft1-right {
        .fr-top {
          .name {
            max-width: 300px;
            font-size: 18px;
            color: #292929;
            line-height: 48px;
            margin-bottom: 22px;
            cursor: pointer;
          }
          .tag {
            width: 84px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #FAFAFA;
            border-radius: 4px;
            border: 1px solid #E6E6E6;
            font-size: 14px;
            color: #808080;
            margin-top: 9px;
            margin-left: 30px;
            .dot {
              display: inline-block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #F66F6A;
              position: relative;
              top: -2px;
              margin-left: 4px;
            }
          }
        }
        .btn {
          margin-bottom: 16px;
          .icon-view {
            padding: 0 20px 0 0;
            font-size: 14px;
            color: #4D4D4D;
            height: 28px;
            line-height: 28px;
            border-right: 1px solid #E6E6E6;
            i {
              font-size: 14px;
              color: #808080;
              margin-right: 3px;
            }
          }
        }
        .tit-wrap {
          .tag {
            display: inline-block;
            vertical-align: top;
            padding: 4px 10px;
            background: #FAFAFA;
            border: 1px solid #E6E6E6;
            border-radius: 15px;
            font-size: 12px;
            color: #4D4D4D;
            line-height: 16px;
            margin: 0 12px 8px 0;
          }
        }
      }
    }
    .ft2 {
      text-align: center;
      font-size: 14px;
      color: #808080;
      border-right: 1px solid #DBE1F6;
    }
    .ft3 {
      cursor: pointer;
      .text {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .num {
        font-size: 24px;
        color: #5074EE;
        line-height: 32px;
        margin-bottom: 16px;
      }
    }
    
  }
  .factory-btm {
    padding: 20px;
    background: #e6ebfa30;
    .top {
      width: 100%;
      margin-bottom: 20px;
      .label-container {
        width: 270px;
        padding: 16px;
        background: #fff;
        border-radius: 4px;
        .icon-tag {
          width: 30px;
          height: 30px;
          background: #029EF5;
          border-radius: 50%;
          margin-right: 8px;
          i {
            font-size: 18px;
            color: #fff;
          }
        }
        .tag {
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          color: #808080;
          line-height: 20px;
          margin: 0 12px 4px 0;
        }
        .default {
          padding: 10px 0;
          font-size: 16px;
          color: #CCCCCC;
        }
        // .color-label {
        //   margin-right: 24px;
        //   flex-shrink: 0;
        // }
        label {
          display: inline-block;
          vertical-align: top;
          flex-shrink: 0;
          font-size: 16px;
          color: #292929;
          line-height: 30px;
          margin-bottom: 4px;
        }
      }
    }
    .bottom {
      .b-top {
        margin-bottom: 16px;
      }
      .bottom-item {
        width: 160px;
      }
      .tit {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        margin-bottom: 4px;
      }
      .text {
        font-size: 16px;
        // font-weight: 600;
        color: #4D4D4D;
        line-height: 22px;
      }
    }
    // .mid {
    //   width: 100%;
    //   // width: 760px;
    //   // height: 268px;
    //   padding:20px;
    //   background: #FAFAFA;
    //   border-bottom: 1px solid #E6E6E6;
    //   > div {
    //     margin-right: 20px;
    //   }
    //   > div:last-child {
    //     margin-right: 0;
    //   }
    // }
    // .table-wrap {
    //   // margin-top: 22px;
    //   width: 641px;
    //   border-radius: 4px;
    //   overflow: hidden;
    //   border: 1px solid #DBE1F6;
    //   .table {
    //     // width: 695px;
    //     box-sizing: border-box;
    //     > div {
    //       border-top: 1px solid #DBE1F6;
    //       &:first-child {
    //         border-top: 0;
    //       }
    //       > div {
    //         width: 100px;
    //         height: 34px;
    //         line-height: 34px;
    //         background-color: #F8F9FF;
    //         padding-left: 20px;
    //         color: #4d4d4d;
    //       }
    //       > div:nth-child(2n) {
    //         background-color: #fff;
    //         width: 239px;
    //         color: #808080;
    //         border-left: 1px solid #DBE1F6;
    //       }
    //       > div:nth-child(3n) {
    //         border-left: 1px solid #DBE1F6;
    //       }
    //       > div:nth-child(4n) {
    //         width: 200px;
    //       }
    //       &:last-child {
    //         div:last-child {
    //           width: 539px;
    //         }
    //       }
    //     }
    //   }
    // }
    // .mid-block {
    //     background: #FFFFFF;
    //     border: 1px solid #DBE1F6;
    //     text-align: center;
    //     font-size: 14px;
    //     color: #808080;
    //     .sale_num {
    //       >div:nth-child(1) {
    //         font-size: 24px;
    //         color: #5074EE;
    //       }
    //     }
    //     .sale_prove {
    //       font-size: 16px;
    //       color: #B3B3B3;
    //     }
    // }
    // .btn-wrap {
    //   width: 100%;
    //   padding: 12px 16px;
    //   .icon-btn {
    //     font-size: 12px;
    //     color: #A9B6E1;
    //     line-height: 32px;
    //     cursor: pointer;
    //     i {
    //       font-size: 14px;
    //       color: #A9B6E1;
    //       margin-right: 8px;
    //     }
    //   }
    // }
  }
}
</style>
